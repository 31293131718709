import { Component, ViewChild } from '@angular/core';
import { TableComponent } from 'src/app/shared/components/layout/table/table.component';
import { IToolbarAction } from 'src/app/core/interfaces/definitions/toolbar-action.definition';
import { TableQueryData } from 'src/app/core/interfaces/definitions/table-query-data.definition';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent<T> {
  @ViewChild(TableComponent) table: TableComponent<T>;
  public queryData: TableQueryData<T> = {};
  public toolbarActions: IToolbarAction[];

  constructor() {}
}
