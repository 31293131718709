/**
 * Traffic Sign Type Model Properties (as defined by the API)
 */
export const TS_TYPE = 'TrafficSignType';
export const TS_TYPE_ID = 'Id';
export const TS_TYPE_IMAGE_URI = 'ImageUri';
export const TS_TYPE_CODE = 'Code';
export const TS_TYPE_NAME = 'Name';
export const TS_TYPE_DISPLAY_NAME = 'DisplayName';
export const TS_TYPE_CATEGORY = 'Category';
export const TS_TYPE_FACE_MATERIAL = 'FaceMaterial';
export const TS_TYPE_REFLECTIVE_COATING = 'ReflectiveCoating';
export const TS_TYPE_WIDTH = 'Width';
export const TS_TYPE_HEIGHT = 'Height';
export const TS_TYPE_DIMENSION_UNIT = 'DimensionUnit';
